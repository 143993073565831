import './bootstrap';
import '../css/app.css';

// Import modules...
import {computed, createApp, h} from 'vue';
import { createInertiaApp, Link as InertiaLink } from '@inertiajs/vue3';
import simpleNotifications from '@dootix/dootix-vue-tailwind/src/modules/simple_notifications';
import 'remixicon/fonts/remixicon.css'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import base from './base.js'
import * as Sentry from "@sentry/vue";
import {
    SimpleButton,
    SimpleCard,
    SimpleModal,
    SimpleBadge,
    SimpleInput,
    SimpleToggle,
    SimpleCheckbox,
    SimpleMultiselect,
    dvt_translate,
    dvt_get_locale,
    dvt_clean_source,
} from '@dootix/dootix-vue-tailwind/dist/dootix-vue-tailwind.umd.cjs'
import Breadcrumbs from "./Components/Breadcrumbs.vue";

const el = document.getElementById('app');
const appName = computed(() => usePage().props.appName)

const themeVariables = {
    simple_card: {
        header_text: {
            base: "text-lg font-semibold",
        }
    },
}

createInertiaApp({
    progress: {
        color: '#4B5563',
    },
    title: (title) => `${title} - ${appName}`,
        resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })

        Sentry.init({
            app: app,
            dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
            environment: import.meta.env.MODE,
            debug: import.meta.env.DEV,
            trackComponents: true,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration(),
            ],
        })

        app.mixin({ methods: { route } })
            .mixin(base)
            .mixin({
                methods: {
                    dvt_translate,
                    dvt_get_locale,
                    dvt_clean_source,
                },
            })
            .provide('themeVariables', themeVariables)
            .provide('simpleNotifications', simpleNotifications)
            .component('InertiaLink', InertiaLink)
            .component('SimpleButton', SimpleButton)
            .component('SimpleCard', SimpleCard)
            .component('SimpleModal', SimpleModal)
            .component('SimpleBadge', SimpleBadge)
            .component('SimpleInput', SimpleInput)
            .component('SimpleToggle', SimpleToggle)
            .component('SimpleCheckbox', SimpleCheckbox)
            .component('SimpleMultiselect', SimpleMultiselect)
            .component('Breadcrumbs', Breadcrumbs)
            .mount(el);
        return app
    },
})
