<template>
    <SimpleBreadcrumbs :home-href="route('home')" :pages="pages">
        <template v-slot:home_a="{ href }">
            <InertiaLink :href="href" class="flex items-center">
                <i class="ri-home-3-line ri-lg" aria-hidden="true" />
                <span class="sr-only">Home</span>
            </InertiaLink>
        </template>
        <template #a="{ page }">
            <InertiaLink :href="page.href">{{ page.name }}</InertiaLink>
        </template>
    </SimpleBreadcrumbs>
</template>

<script>
import SimpleBreadcrumbs from '@dootix/dootix-vue-tailwind/src/components/navigation/SimpleBreadcrumbs.vue'

export default {
    name: "Breadcrumbs",
    components: {
        SimpleBreadcrumbs,
    },
    props: {
        pages: { required: false, type: Object, default: () => {} }
    },
};
</script>
